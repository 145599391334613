export default {
  'wages.title': 'Salari', //Löhne
  'wages.create': 'Registrare salario', //Lohn erfassen
  'wages.report': 'Notifica dei salari', //Lohnmeldung
  'wages.report.stepTitle01': 'Dati', //Angaben
  'wages.report.stepTitle02': 'Salari', //Löhne
  'wages.report.txt01': 'Rispondere alle seguenti domande.', //Bitte beantworten Sie die untenstehenden Fragen.
  'wages.report.txt02': 'Il nostro team della Cassa pensioni rimane a disposizione in caso di problemi: 044 258 84 50', //Bei Problemen hilft Ihnen unser Pensionskassenteam gerne weiter: 044 258 84 50
  'wages.report.txt03': 'Dati sui previdenza professionale per l’esercizio annuo', //Angaben über die berufliche Vorsorge für das Geschäftsjahr
  'wages.report.txt04': 'Necessitiamo dei seguenti dati affinché la nostra fondazione possa far valere un’eventuale sovvenzione per l’anno {year} nel fondo di garanzia per struttura d’età sfavorevole.', //Damit unsere Stiftung einen allfälligen Zuschuss für das Jahr 2023 beim Sicherheitsfond wegen ungünstiger Altersstruktur geltend machen kann, benötigen wir von Ihnen folgende Angaben.
  'wages.report.question01': '1. Esistono per l’azienda associata contratti d’affiliazione per l’assicurazione di base (secondo LPP) con diversi istituti di previdenza?', //1. Bestehen für die Mitgliedfirma Anschlussverträge für die Grundversicherung (gemäss BVG) bei mehreren Vorsorgeeinrichtungen?
  'wages.report.question02': '2. Il titolare dell’azienda associata è lavoratore autonomo o lavoratore autonomo con propri impiegati?', //2. Gilt der Inhaber der Mitgliedfirma als Selbständigerwerbend oder Selbständigererwerbend mit eigenen Angestellten?
  'wages.report.confirmation': 'Con la presente confermo la correttezza dei miei dati.', //Hiermit bestätige ich die Richtigkeit meiner Daten.
  'wages.report.address': 'Nome/indirizzo dell’istituto di previdenza', //Name/Adresse der Vorsorgeeinrichtung
  'wages.report.success': 'La mutazione di massa è completa. Ora puoi chiudere la finestra. In caso di errori, contattaci.', //Die Massenmutation ist abgeschlossen. Sie können das Fenster nun schliessen. Bei allfälligen Fehleren kontaktieren Sie uns bitte.
  'wages.report.alert.success': 'La tua busta paga è stata inviata.', // Ihre Lohnmeldung wurde übermittelt.
  'wages.report.pending': 'Attendere la trasmissione di tutti i salari.', //Bitte warten Sie bis alle Löhne übertragen wurden.
  'wages.personalkategorie': 'Categoria salario', //Lohnkategorie
  'wages.beschaeftigungsgrad': 'Grado di occupazione (%)', //Pensum (%)
  'wages.betrag': 'Salario annuale (CHF)', //Jahreslohn
  'wages.status': 'stato', //Status
  'wages.report.button.next.label': 'Continua', // Weiter
  'wages.signature.reset': 'Riportare', // Zurücksetzen
  'wages.signature.fullname': 'Nome e cognome', // Vorname & Name
  'wages.signature.label': 'Firma', // Unterschrift
  'wages.signature.alert': 'Inserisci il tuo nome e cognome nel campo sottostante e firma direttamente nel campo', // Bitte tragen Sie im untenstehenden Feld Ihren Vor- und Nachnamen ein und unterschreiben Sie direkt im Feld

};
