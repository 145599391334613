export default {
  'login.login.message': 'Veuillez vous connecter avec votre adresse e-mail et votre mot de passe.', // Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.
  'login.message.success': 'Connexion réussie.', // Login erfolgreich.
  'login.button.passwordForget': 'Mot de passe oublié', // Passwort vergessen
  'login.message.passwordForget': 'Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser. Saisissez votre adresse e-mail en bas. Nous allons vous envoyer un code d’activation.', // Falls Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen. Tragen Sie unten Ihre E-Mail-Adresse ein. Wir werden Ihnen per E-Mail einen Aktivierungscode senden.
  'login.button.activateLogin': 'Activer l’accès', // Zugang aktivieren
  'login.message.activateLogin': 'Activez votre accès et définissez un nouveau mot de passe.', // Aktivieren Sie Ihren Zugang und setzen Sie ein neues Passwort.
  'login.button.register': 'Demander un accès ', // Zugang beantragen
  'login.message.register01': 'Vous avez besoin d’identifiants de connexion supplémentaires pour l’un-e de vos employé-e-s?', // Sie benötigen ein zusätzliches Login für eine/n Ihrer Angestellten?
  'login.message.register02': 'Vous pouvez les demander via le formulaire ci-dessous.', // Dieses können Sie über das unterstehende Formular anfordern.
  'login.message.register03': 'Votre demande de renseignements a été envoyée.', // Ihre Anfrage wurde verschickt.
  'login.register.deOption': 'Allemand', // Deutsch
  'login.register.frOption': 'Italien', // Italienisch
  'login.register.itOption': 'Français', // Französisch


  'login.token.success': 'Nous vous avons envoyés le code de vérification.', // Wir haben Ihnen den Verifizierungscode gesendet.
  'login.token.message': 'Saisissez le code de vérification que nous avons envoyé.', // Bitte geben Sie den Verifizierungscode ein, den wir Ihnen gesendet haben.

  'login.message.passwordSuccess': 'D’autres instructions ont été envoyées à votre adresse e-mail.', // Weitere Anweisungen wurden an Ihre E-Mail-Adresse versandt.
  'login.passwordForget.success' : 'Votre nouveau mot de passe a été enregistré. Veuillez vous connecter.', // Ihr neues Passwort wurde gespeichert. Bitte loggen Sie sich ein.
  'login.activate.success' : 'Votre accès a été activé. Veuillez vous connecter.', // Ihr Zugang wurde aktiviert. Bitte loggen Sie sich ein.

  'form.tfa.email': 'vérification de l E-mail', // E-Mail Verifizierung
  'form.tfa.sms': 'Vérification SMS', // SMS Verifizierung
};
