export default {
  'login.login.message': 'Registrarsi con il proprio indirizzo e-mail e la propria password.', //Bitte melden Sie sich mit Ihrer E-Mail-Adresse und Ihrem Passwort an.
  'login.message.success': 'Accesso avvenuto correttamente.', //Login erfolgreich.
  'login.button.passwordForget': 'Password dimenticata', //Passwort vergessen
  'login.message.passwordForget': 'È possibile procedere al ripristino della password se è stata dimenticata. Immettere il proprio indirizzo e-mail. Invieremo un codice di attivazione fornito.', //Falls Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen. Tragen Sie unten Ihre E-Mail-Adresse ein. Wir werden Ihnen per E-Mail einen Aktivierungscode senden.
  'login.button.activateLogin': 'Attivazione dell’accesso', //Zugang aktivieren
  'login.message.activateLogin': 'Attivare l’accesso ed impostare una nuova password.', //Aktivieren Sie Ihren Zugang und setzen Sie ein neues Passwort.
  'login.button.register': 'Richiesta di accesso', //Zugang beantragen
  'login.message.register01': 'È necessario un ulteriore accesso per uno/a degli impiegati?', //Sie benötigen ein zusätzliches Login für eine/n Ihrer Angestellten?
  'login.message.register02': 'È possibile richiederlo compilando il seguente modulo.', //Dieses können Sie über das unterstehende Formular anfordern.
  'login.message.register03': 'La richiesta è stata inoltrata.', //Ihre Anfrage wurde verschickt.
  'login.register.deOption': 'Tedesco', //Deutsch
  'login.register.frOption': 'Italiano', //Italienisch
  'login.register.itOption': 'Francese', //Französisch


  'login.token.success': 'Abbiamo inviato il codice di verifica.', //Wir haben Ihnen den Verifizierungscode per E-Mail gesendet.
  'login.token.message': 'Inserire il codice di verifica inviato.', //Bitte geben Sie den Verifizierungscode ein, den wir Ihnen per E-Mail gesendet haben.

  'login.message.passwordSuccess': 'Ulteriori istruzioni sono state inviate all’indirizzo e-mail fornito.', //Weitere Anweisungen wurden an Ihre E-Mail-Adresse versandt.
  'login.passwordForget.success' : 'La nuova password è stata memorizzata. Effettuare l’accesso.', //Ihr neues Passwort wurde gespeichert. Bitte loggen Sie sich ein.
  'login.activate.success' : 'L’accesso è stato attivato. Effettuare l’accesso.', //Ihr Zugang wurde aktiviert. Bitte loggen Sie sich ein.

  'form.tfa.email': 'verifica email', // E-Mail Verifizierung
  'form.tfa.sms': 'Verifica SMS', // SMS Verifizierung
};
