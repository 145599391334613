import { Avatar, Tooltip, Image, Carousel, Typography, Table, Button, Input, Divider } from 'antd';
import { UserOutlined, AntDesignOutlined, SelectOutlined } from '@ant-design/icons';
import { useIntl, history, FormattedMessage, SelectLang, useModel } from 'umi';
import { getReglements } from '@/services/ant-design-pro/api';
import ProTable from '@ant-design/pro-table';
import React, { useState, useRef, useEffect } from 'react';
import Helper from '@/helper/Helper';


import './assets/css/index.css';





const AvatarSelectTable = (props) => {

    const intl = useIntl();

    const { initialState, setInitialState } = useModel('@@initialState');
    const [dataSource, setDataSource] = useState(props.companyCollection);
    const onCompanyClick = (company, onClose=false) => {

      setInitialState((s) => ({ ...s, selectedCompany: company })).then(function(e) {
        localStorage.setItem('selectedCompany', JSON.stringify(company));

        let reglements = getReglements({}, {}).then(function(response) {
          //let variables = Helper.getVariables();

          let pksVariables = Helper.getVariables();
          let pksVariablesOrg = Helper.getOrgVariables();


          if(pksVariables) {
            pksVariablesOrg['Reglement'] = response.data;
            localStorage.setItem('variablesOrg', JSON.stringify(pksVariablesOrg));
            pksVariables = Helper.translateVariables(pksVariablesOrg);
            localStorage.setItem('variables', JSON.stringify(pksVariables));
          }
          return response;
        });

        history.push('/dashboard');

        if(onClose) {
          onClose();
        }
        //window.location = '/welcome';
      });
    }


    const columns = [
        {
            title: 'ID',
            width: 80,
            dataIndex: 'betriebId',
        },
        {
            title: intl.formatMessage({id: 'avatarSelect.company', defaultMessage: 'Betrieb'}),
            dataIndex: 'name',
        },
        {
          title: '',
          dataIndex: '',
          key: 'x',
          render: (text, record, index) => (
            <div style={{textAlign: 'center'}}>
              <Button onClick={() => onCompanyClick(record, props.onClose)} type="primary" shape="circle" icon={<SelectOutlined />} />
            </div>
          ),
        },
    ];


    const search = (value) => {
      const baseData = props.companyCollection;

      const filterTable = baseData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      );

      setDataSource(filterTable);
    };






  return (
    <>
      <div className="company-select-bar">
        <div>

          <Input.Search
            placeholder={intl.formatMessage({id: 'avatarSelect.search', defaultMessage: 'Nach Firma suchen…'})}
            enterButton
            onSearch={(value) => search(value)}
          />

          <Divider />

          <Table
            rowKey={'betriebId'}
            pagination={{
              pageSize: 5,
              showSizeChanger: false
            }}
            filterMultiple={true}
            rowSelection={false}
            dataSource={dataSource}
            columns={columns}
           />

       </div>

      </div>
    </>
  );
};
export default AvatarSelectTable;
