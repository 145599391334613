export default {
  'form.textPlaceholder': 'Digita', // Bitte eingeben
  'form.required': 'Campo obbligatorio', //Pflichtfeld
  'form.email': 'Indirizzo e-mail', //E-Mail Adresse
  'form.email.validate': 'Indirizzo e-mail non valido', //Keine gültige E-Mail Adresse
  'form.password': 'Password', //Passwort
  'form.firstname': 'Nome', //Vorname
  'form.lastname': 'Cognome', //Nachname
  'form.phone': 'Telefono', //Telefon
  'form.companyNumber': 'Numero d’identificazione delle imprese', //Firmennummer
  'form.languageID': 'Lingua', //Sprache
  'form.verificationCode': 'Codice di verifica', //Verifizierungscode
  'form.activationCode': 'Codice di attivazione', //Aktivierungscode
  'form.newPassword': 'Nuova password', //Neues Passwort
  'form.newPasswordConfirm': 'Confermare nuova password', //Neues Passwort bestätigen
  'form.newPassword.error': 'Le password non corrispondono.', //Die Passwörter stimmen nicht überein.
  'form.yes': 'Sì', //Ja
  'form.no': 'No', //Nein
  'form.validFrom': 'Validità da', //Gültig ab
  'form.contact.title': 'Inoltrare richiesta', //Anfrage stellen
  'form.contact.subject': 'Oggetto', //Betreff
  'form.contact.message': 'Messaggio', //Ihre Nachricht
  'form.contact.subject01': 'Richiesta generale', //Allgemeine Anfrage
  'form.contact.subject02': 'Supporto', //Support
  'form.contact.subject03': 'Offerta regolamento', //Reglement Offerte
  'form.contact.success': 'La richiesta è stata inoltrata.', //Ihre Anfrage wurde verschickt.
};
