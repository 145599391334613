// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import UnorderedListOutlined from '@ant-design/icons/UnorderedListOutlined'

export default {
  HomeOutlined,
UserOutlined,
BankOutlined,
UnorderedListOutlined
}
    