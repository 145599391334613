export default {

  'insurants.Postadresse': 'Recapito postale', //Postadresse

  'insurants.entry.title': 'Entrata', //Eintritt
  'insurants.entry.create': 'Registrazione dell’entrata', //Eintritt erfassen
  'insurants.exit.title': 'Uscita', //Austritt
  'insurants.exit.create': 'Registrazione dell’uscita', //Austritt erfassen
  'insurants.disability.title': 'Incapacità al lavoro', //Arbeitsunfähigkeit
  'insurants.disability.create': 'Registrare incapacità al lavoro', //Arbeitsunfähigkeit erfassen
  'insurants.geburtsdatum': 'Data di nascita', //Geburtsdatum
  'insurants.sozversId': 'Numero di assicurazione sociale', //Sozialversicherungsnummer
  'insurants.versId': 'ID assicurato', //Versicherten-ID
  'insurants.exit.tooltip': 'Uscita in sospeso {date}', //Pendenter Austritt
  'insurants.status.exit': 'Uscita in sospeso', //Pendenter Austritt
  'insurants.status.active': 'Attivo', //Aktiv
  'insurants.button.details': 'Dettagli', //Details
  'insurants.person.button.edit': 'Modificare dati personali', //Personalien bearbeiten
  'insurants.address.button.add': 'Registrare indirizzo', //Adresse erfassen
  'insurants.reentry': 'Rientro', //Wiedereintritt
  'insurants.persons.tab.title': 'Dati personali', //Personalien

  'insurants.form.cancelText': 'Si desidera non apportare le modifiche? I dati andranno persi.', //Wollen Sie die Bearbeitung wirklich verlassen? Ihre Daten gehen verloren.
  'insurants.form.cancel.confirm': 'Confermare', //Bestätigen
  'insurants.form.cancel.cancel': 'Annullare', //Abbrechen

  'insurants.form.save': 'Salvare & avanti', //Speichern & weiter
  'insurants.form.finish': 'Chiudere entrata', //Eintritt abschliessen
  'insurants.form.back': 'indietro', //zurück

  'insurants.form.step.uebersicht.title': 'Panoramica', //Übersicht

  'insurants.form.Reglemente': 'Regolamenti', //Reglemente
  'insurants.form.zivilstand.label': 'Stato civile', //Zivilstand
  'insurants.form.sozId.label': 'Numero di assicurazione sociale', //Sozialversicherungsnummer
  'insurants.form.zivilstand.label.valid': 'Stato civile, valido da', //Zivilstand, gültig ab
  'insurants.form.zivilstandGueltigAb.label': 'Stato civile, valido da', //Zivilstand, gültig ab
  'insurants.form.spracheId.label': 'Lingua della corrispondenza', //Korrespondenzsprache

  'insurants.form.step.start.title': 'Inizio', //Start
  'insurants.form.step.type.label': 'Tipo di entrata', //Art des Eintritts
  'insurants.form.step.type.option01': 'Entrata', //Eintritt
  'insurants.form.step.type.option02': 'Rientro', //Wiedereintritt

  'insurants.form.step.person.title': 'Dati personali', //Personalien
  'insurants.form.step.insurant.label': 'Assicurato', //Versicherter
  'insurants.form.anrede.label': '​Appellativo', //Anrede
  'insurants.form.vorname.label': 'Nome', //Vorname
  'insurants.form.nachname.label': 'Cognome', //Nachname
  'insurants.form.geburtsdatum.label': 'Data di nascita', //Geburtsdatum
  'insurants.form.geburtsdatum.minimum': 'Età minima 17 anni', //Geburtsdatum
  'insurants.form.sozversId.label': 'Data di nascita', //Sozialversicherungsnummer
  'insurants.form.sozversId.invalid': 'Numero di previdenza sociale non valido', //Sozialversicherungsnummer ungültig



  'insurants.form.step.address.title': 'Indirizzo', //Adresse
  'insurants.form.step.address.search.placeholder': 'Entrez la rue et le numéro pour rechercher et remplir l’adresse automatiquement IT',
  'insurants.form.step.address.search.label': 'adresse de recherche IT',
  'insurants.form.adresszeile2.label': 'Via e numero civico', //Strasse + Nr.
  'insurants.form.plz.label': 'NPA', //Plz
  'insurants.form.wohnort.label': 'Città', //Ort
  'insurants.form.adresszeile1.label': 'Indirizzo aggiuntivo', //Adresszusatz
  'insurants.form.land.label': 'Nazione', //Land
  'insurants.form.Kontaktdaten': 'Dati di contatto', //Kontaktdaten
  'insurants.form.telefon.label': 'Telefono', //Telefon
  'insurants.form.email.invalid': 'nessun indirizzo email valido', // Keine gültige E-Mail Adresse
  'insurants.form.email.label': 'Indirizzo e-mail', //E-Mail Adresse
  'insurants.form.address.label': 'Indirizzo', //Adresse



  'insurants.form.step.wage.title': 'Dati del salario', //Lohndaten
  'insurants.form.gueltigAb.label': 'Data di entrata', //Eintrittsdatum
  'insurants.form.gueltigAb.minimum': 'Primo ingresso 01.01.2021', // Frühester Eintritt 01.01.2021
  'insurants.form.beschaeftigungsGrad.label': 'Grado di occupazione (%)', //Pensum (%)
  'insurants.form.betrag.label': 'Salario annuale (CHF)', //Jahreslohn (CHF)
  'insurants.form.Reglement': 'Regolamento', //Reglement
  'insurants.form.personalKategorie.label': 'Categoria salario', //Lohnkategorie


  'insurants.form.personData.empty': '',
  'insurants.form.personData.card.title': 'Dati personali', //Personalien
  'insurants.form.address.card.title': 'Indirizzo e contatto', //Adresse & Kontakt
  'insurants.form.addressData.empty': '',
  'insurants.form.wage.card.title': 'Dati del salario', //Lohndaten
  'insurants.form.wageData.empty': '',




  'insurants.form.exit.message.success': 'La notifica di uscita è stata inviata correttamente.', // Die Austrittmeldung wurde erfolgerich übermittelt.
  'insurants.form.exit.gueltigAb.label': 'Data di uscita', //Austrittsdatum
  'insurants.form.exit.austrittsgrundId.label': 'Motivo di uscita', //Austrittsgrund




  'insurants.form.disability.create.label': 'Registrazione dell’incapacità al lavoro', //Arbeitsunfähigkeit erfassen
  'insurants.form.disability.type.label': 'Tipo di incapacità al lavoro', //Art der Arbeitsunfähigkeit
  'insurants.form.disability.type.Krankheit': 'Malattia', //Krankheit
  'insurants.form.disability.type.Unfall': 'Incidente', //Unfall
  'insurants.form.disability.disability_percent.label': 'Perdita di lavoro (%)', //Arbeitsausfall (%)
  'insurants.form.disability.date.label': 'Incapacità al lavoro dal', //Arbeitsunfähig seit
  'insurants.form.disability.address_name.label': 'Nome e indirizzo dell’assicurazione infortuni e dell’indennità giornaliera per malattia', //Name und Adresse der Krankentaggeld- bzw. Unfallversicherung
  'insurants.form.disability.address.card.title': 'Altre informazioni necessarie', //Zusätzlich benötigte Informationen
  'insurants.form.disability.txt01': 'Allegare i seguenti documenti alla richiesta', //Bitte legen Sie dem Antrag folgende Dokumente bei
  'insurants.form.disability.txt02': 'Taggeldkarte', //Taggeldkarte
  'insurants.form.disability.txt03': 'Liquidazioni disponibili per indennità giornaliera', //vorhandene Krankentaggeldabrechnungen
  'insurants.form.disability.txt04': 'Liquidazione finale delle indennità giornaliere', //Schlussabrechnung der Taggelder
  'insurants.form.disability.txt05': 'Schlussabrechnung der Taggelder', //Arztzeugnisse
  'insurants.form.disability.txt06': 'IV-Modulo di registrazione (se presente)', //IV-Anmeldeformular (wenn vorhanden)
  'insurants.form.disability.txt07': 'IV-Modulo per il datore di lavoro (se presente)', //IV-Formular für den Arbeitgeber(wenn vorhanden)
  'insurants.form.disability.txt08': 'IV-Modulo per il datore di lavoro (se presente)', //IV-Verfügung(wenn vorhanden)
  'insurants.form.disability.file.label': 'Allegati', //Anhänge
  'insurants.form.disability.file.invalid': 'Limite file di 21 MB superato.', // Datei Limit von 21MB überschritten.



  'insurants.form.wage.exit.message': 'È già stata registrata un’uscita per questa persona. Per un cambio di stipendio dopo la data di partenza, ti chiediamo di utilizzare la funzione di rientro.', // 'Für diese Person wurde bereits ein Austritt erfasst. Für eine Lohnmutation nach dem Austrittsdatum bitten wir Sie die Funktion Wiedereintritt zu verwenden.
  'insurants.form.wage.gueltigAb.label': 'Validità da', //Gültig ab
  'insurants.form.wage.gueltigBis.label': 'Validità fino a', //Gültig Bis
  'insurants.wage.selected.count': 'Selezionato', //Ausgewählt:



  'insurants.details.wage.card.title': 'Salari', //Löhne
  'insurants.form.wage.edit.title': 'modifica paga', // Lohn bearbeiten
  'insurants.form.wage.add.title': 'Registrare salario', //Lohn erfassen


  'insurants.contribution.card.title': 'Contributi mensili', //Monatsbeiträge
  'insurants.contribution.beitragstyp.label': 'Tipo di contributo', //Beitragstyp
  'insurants.contribution.betrag.label': 'Importo', //Betrag
  'insurants.contribution.betragAG.label': 'Contributo annuale datore di lavoro (CHF)', //Jahresbeitrag Arbeitgeber (CHF)
  'insurants.contribution.betragAN.label': 'Contributo annuale lavoratore (CHF)', //Jahresbeitrag Arbeitnehmer (CHF)
  'insurants.contribution.total.label': 'Totale contributo annuale (CHF)', //Total Jahresbeitrag (CHF)
  'insurants.contribution.month.title': 'Contributi mensili', //Monatsbeiträge


  'insurants.form.address.edit': 'Modifica indirizzo', // Adresse bearbeiten

  'insurants.tabs.Unterlagen.label': 'Documenti', //Unterlagen
  'insurants.upload.message.title': 'Caricare documenti', //Dokumente hochladen
  'insurants.upload.message': 'Immettere lo scopo dei documenti trasmessi nel campo del messaggio.', //Bitte im Nachrichtenfeld den Zweck der übermittelten Unterlagen eintragen.


  'insurant.form.edit.message.success': 'Le tue modifiche sono state salvate correttamente.', // Ihre Änderungen wurden erfolgreich gespeichert.
  'insurants.address.empty': 'Nessun indirizzo disponibile',

  'insurants.form.exit.edit.title': 'Uscita di massa',
  'exit.report.success': 'L uscita di massa è stata completata. Si può chiudere la maschera. Nel caso in di errori, vi preghiamo di contattarci.',
  'exit.report.pending': 'Si prega di attendere che tutte le uscite siano stati trasferiti',



};
