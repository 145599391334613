import login from './it-IT/login';
import form from './it-IT/form';
import translatedMessages from './it-IT/translatedMessages';
import footer from './it-IT/footer';
import menu from './it-IT/menu';
import avatarSelect from './it-IT/avatarSelect';
import settings from './it-IT/settings';
import dashboard from './it-IT/dashboard';
import insurants from './it-IT/insurants';
import wages from './it-IT/wages';
import activity from './it-IT/activity';
import variables from './it-IT/variables';


export default {
  ...login,
  ...form,
  ...translatedMessages,
  ...footer,
  ...menu,
  ...avatarSelect,
  ...settings,
  ...dashboard,
  ...insurants,
  ...wages,
  ...activity,
  ...variables,
  'logout.success': 'Ti sei disconnesso con successo.', // Du hast dich erfolgreich abgemeldet.
  'loading': 'attendere prego...', // Bitte warten
};
