import React, { useState, useEffect } from 'react';

import { PageLoading } from '@ant-design/pro-layout';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import Header from '@/components/Header';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { BookOutlined, LinkOutlined } from '@ant-design/icons';
import { useModel} from 'umi';
import moment from 'moment-timezone';

import logger from './helper/Logger';

import 'antd/dist/antd.variable.min.css';
import './assets/css/app.css';
import './assets/css/responsive.css';
import 'antd-country-phone-input/dist/index.css';

import '../config/interceptors.js';

import { ConfigProvider } from 'antd';
import Helper from '@/helper/Helper';

const loginPath = '/user/login';


// Set CI style settings
ConfigProvider.config({
  theme: {
    primaryColor: '#cb4d53',
    linkColor: '#cb4d53',
  }
});




if(!localStorage.getItem('umi_locale')) {
  localStorage.setItem('umi_locale', 'de-DE');
}


moment.tz.setDefault('Europe/Zurich');



export const initialStateConfig = {
  loading: <PageLoading />,
};
/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */

export async function getInitialState() {



  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      //console.log(msg);
      return msg;
    } catch (error) {
      //console.log(error);
      //history.push(loginPath);
    }
    return undefined;
  };

  if (history.location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();

    let selectedCompany = Helper.getSelectedCompany();

    return {
      fetchUserInfo,
      currentUser,
      selectedCompany: selectedCompany,
      settings: {},
    };
  }

  return {
    fetchUserInfo,
    selectedCompany: {},
    settings: {},
  };
}

export const layout = ({ initialState }) => {


  if(localStorage.getItem('variablesOrg')) {
    let pksVariables = Helper.getOrgVariables();
    pksVariables = Helper.translateVariables(pksVariables);
    localStorage.setItem('variables', JSON.stringify(pksVariables));
  }


  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    headerTitleRender: () => <Header />,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      //console.log(initialState);
      if ((!initialState?.currentUser || !initialState?.selectedCompany) && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    menuHeaderRender: undefined,
    ...initialState?.settings,
  };
};



window.connectModel = (key, name) => {
  return (WrappedComponent) => {
    return (props) => {
      const model = useModel(name);
      const data = { [key]: model };
      return <WrappedComponent {...props} {...data} />;
    };
  };
};
