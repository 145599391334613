import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import AvatarSelectTable from './AvatarSelectTable'
import { useIntl } from 'umi';
import Helper from '@/helper/Helper'

const AvatarSelectMenu = () => {

  const intl = useIntl();

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <span onClick={showDrawer}>
        {intl.formatMessage({id: 'avatarSelect.change', defaultMessage: 'Firma ändern'})}
      </span>
      <Drawer title={intl.formatMessage({id: 'avatarSelect.drawerTitle', defaultMessage: 'Wählen Sie die gewünschte Firma aus.'})} placement="bottom" onClose={onClose} visible={visible} height={'100%'}>
          <><AvatarSelectTable onClose={onClose} companyCollection={Helper.getCompanyCollection()} /></>
      </Drawer>
    </>
  );
};

export default AvatarSelectMenu;
