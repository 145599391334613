import log from 'loglevel';
import React, { Component } from 'react';

const logEnabled = true;
let logger;
if(logEnabled) {
  log.enableAll();
}


class Logger extends Component {

  constructor(props) {
    super(props);
  }

  static logAction(message, tag = '', type = '', info = '') {

    if(!logEnabled) {
      return;
    }
  
    if(type == 'info') {
      //log.info(message);
    }

    if(type == 'warn') {
      //log.warn(message);
    }
  }
}

export default Logger;
