// @ts-ignore

/* eslint-disable */
import { request, useModel, useIntl } from 'umi';
import { Alert, message, Tabs } from 'antd';
import Helper from '@/helper/Helper';
import Logger from '@/helper/Logger';



/** GET /api/checkMaintance */
export async function checkMaintance(options) {
  return fetch('/api/checkMaintance?_format=json');
}

/** GET /api/currentUser */
export async function currentUser(options) {

  var userInfo = localStorage.getItem('userInfo');
  userInfo = JSON.parse(userInfo);

  return request('/api/rest/webuser/'+userInfo.current_user.uid[0].value+'?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}


/** GET /api/jwt/token */
export async function refreshToken(options) {


  return request('/api/jwt/token?_format=json', {
    skipErrorHandler: true,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  });
}

/** POST /api/logout */
export async function outLogin(options) {



  var userInfo = localStorage.getItem('userInfo');
  userInfo = JSON.parse(userInfo);

  const returnResponse = request('/api/user/logout?token='+userInfo.logout_token+'&_format=json', {
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    ...(options || {}),
  }).then(function(response) {

    Logger.logAction(response, 'outLogin', 'info', '');

    Helper.clearLocalStorage();
  })
  .catch(function(error) {

    Logger.logAction(error, 'outLogin', 'error', '');

    Helper.clearLocalStorage();
  });

  message.success(Helper.translateString('Sie wurden erfolgreich abgemeldet.'));

  return returnResponse;
}

/** POST /api/login */
export async function login(body, options) {

  const returnResponse = request('/api/user/login?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'login', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'login', 'error', '');
    return error;
  });

  return returnResponse;
}



/** POST /api/lostPassword */
export async function lostPassword(body, options) {

  const returnResponse = request('/api/user/lost-password?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'lostPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'lostPassword', 'error', '');
    return error;
  });

  return returnResponse;
}


/** POST /api/lostPassword */
export async function resetPassword(body, options) {

  const returnResponse = request('/api/user/lost-password-reset?_format=json', {
    skipErrorHandler: true,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'resetPassword', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'resetPassword', 'error', '');
    return error;
  });
  return returnResponse;
}




/**GET /api/notices */
export async function getNotices(options) {
  return request('/api/notices?_format=json', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getNotices', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getNotices', 'error', '');
    return error;
  });
}




/*
 * Reglement api
 *
*/
/** GET /api/reglement */
export async function getReglements(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=Reglement&betriebId=' + selectedCompany.betriebId, {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getReglements', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getReglements', 'error', '');
    return error;
  });
}



/*
 * insurants api
 *
*/

/** GET /api/insurants */
export async function getInsurants(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=VersicherteGrup&betriebId=' + selectedCompany.betriebId, {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getInsurants', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getInsurants', 'error', '');
    return error;
  });
}


/** GET /api/admissionInsurants */
export async function getAdmissionInsurants(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=VersicherteGrupAdmission&betriebId=' + selectedCompany.betriebId, {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getAdmissionInsurants', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getAdmissionInsurants', 'error', '');
    return error;
  });
}


export async function getInsurant(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=Versicherte&betriebId=' + selectedCompany.betriebId + '&versInfos=Loehne,SparbeitraegeAN,SparbeitraegeAG,RisikobeitraegeAN,RisikobeitraegeAG,UebrigeBeitraegeAN,UebrigeBeitraegeAG,PendenterAustritt,Personalkategorie,BeitraegeMonat', {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getInsurant', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getInsurant', 'error', '');
    return error;
  });
}



export async function getInsurantAdmission(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=Versicherte&betriebId=' + selectedCompany.betriebId + '&versInfos=Loehne,PendenterAustritt,Personalkategorie', {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getInsurant', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getInsurant', 'error', '');
    return error;
  });
}



export async function updateInsurant(params, options) {
    params['action'] = 'updateInsurant';
    return request('/api/rest/pks/versicherte/create', {
      method: 'POST',
      skipErrorHandler: true,
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'updateInsurant', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'updateInsurant', 'error', '');
      return error;
    });
}

export async function addInsurant(params, options) {
    params['action'] = 'addInsurant';
    return request('/api/rest/pks/versicherte/create', {
      method: 'POST',
      skipErrorHandler: true,
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'addInsurant', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'addInsurant', 'error', '');
      return error;
    });
}

export async function reentryInsurant(params, options) {
    params['action'] = 'reentryInsurant';
    return request('/api/rest/pks/versicherte/create', {
      method: 'POST',
      skipErrorHandler: true,
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'reentryInsurant', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'reentryInsurant', 'error', '');
      return error;
    });
}



/*
 * Wages api
 *
*/

/** GET /api/wages */
export async function getWages(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=VersicherteGrupInfosLoehne&betriebId=' + selectedCompany.betriebId, {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getWages', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getWages', 'error', '');
    return error;
  });
}

export async function getInsurantWages(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/lohnmutation/'+selectedCompany.betriebId+'?_format=json&action=LohnMutation', {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getInsurantWages', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getInsurantWages', 'error', '');
    return error;
  });
}



export async function addWage(params, options) {
    let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
    params['action'] = 'SingleLohnMutation';
    params['betriebId'] = selectedCompany.betriebId;
    return request('/api/rest/pks/lohnmutation/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'addWage', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'addWage', 'error', '');
      return error;
    });

}

export async function massUpdateWage(params, options) {
    let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));

    params['action'] = 'MassLohnMutation';
    params['betriebId'] = selectedCompany.betriebId;

    return request('/api/rest/pks/lohnmutation/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'massUpdateWage', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'massUpdateWage', 'error', '');
      return error;
    });
}





/*
 * Contributions api
 *
*/

/** GET /api/Contribution */
export async function getContributions(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=VersicherteGrupInfosBeitraege&betriebId=' + selectedCompany.betriebId, {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getContributions', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getContributions', 'error', '');
    return error;
  });
}



export async function getInsurantContributions(params, options) {

  let selectedCompany = JSON.parse(localStorage.getItem('selectedCompany'));
  return request('/api/rest/pks/betrieb/'+selectedCompany.betriebId+'?_format=json&action=Versicherte&betriebId=' + selectedCompany.betriebId + '&versInfos=SparbeitraegeAN,SparbeitraegeAG,RisikobeitraegeAN,RisikobeitraegeAG,UebrigeBeitraegeAN,UebrigeBeitraegeAG,BeitraegeMonat', {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getInsurant', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getInsurant', 'error', '');
    return error;
  });
}



/*
 * Activity Logs api
 *
*/

/** GET /api/logs */
export async function getActivityLogs(params, options) {

  return request('/api/rest/pks/logs/'+params['betriebId']+'?_format=json', {
    method: 'GET',
    skipErrorHandler: true,
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...params },
    ...(options || {}),
  }).then(function(response) {
    Logger.logAction(response, 'getActivityLogs', 'info', '');
    return response;
  }).catch(function(error) {
    Logger.logAction(error, 'getActivityLogs', 'error', '');
    return error;
  });
}




/*
 * Address api
 *
*/

export async function addAddress(params, options) {
    return request('/api/rest/pks/adresse/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'addAddress', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'addAddress', 'error', '');
      return error;
    });
}




/*
 * Austritt api
 *
*/

export async function addAustritt(params, options) {
    return request('/api/rest/pks/austritt/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'addAustritt', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'addAustritt', 'error', '');
      return error;
    });
}




/*
 * Upload api
 *
*/

export async function uploadFiles(params, options) {
    return request('/api/rest/pks/upload/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'uploadFiles', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'uploadFiles', 'error', '');
      return error;
    });
}





/*
 * Form api
 *
*/

export async function submitDrupalForm(params, options) {
    return request('/api/webform_rest/submit?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'submitDrupalForm', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'submitDrupalForm', 'error', '');
      return error;
    });
}

export async function submitRegisterForm(params, options) {
    return request('/api/rest/pks/register/create', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'submitRegisterForm', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'submitRegisterForm', 'error', '');
      return error;
    });
}



/*
 * Form api
 *
*/

export async function drupalUploadFileTest(params, options) {
    return request('/api/entity/file?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      headers: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'drupalUploadFileTest', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'drupalUploadFileTest', 'error', '');
      return error;
    });
}


export async function drupalUploadFile(params, options) {
  Logger.logAction({params, options}, 'drupalUploadFile', 'info', '');
    return request('/api/rest/pks/file/create?_format=json', {
      skipErrorHandler: true,
      method: 'POST',
      data: params,
      hheaders: {
        'Content-Type': 'application/json',
      },
      ...(options || {}),
    }).then(function(response) {
      Logger.logAction(response, 'drupalUploadFile', 'info', '');
      return response;
    }).catch(function(error) {
      Logger.logAction(error, 'drupalUploadFile', 'error', '');
      return error;
    });
}
