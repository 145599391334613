import React, { Component } from 'react'
import { IdleTimerProvider, IdleTimerConsumer } from 'react-idle-timer'
import { Modal, Button, Row, Col, Alert, Divider, Space } from 'antd';
import { history } from 'umi';
import Countdown from 'react-countdown';
import { injectIntl, FormattedMessage } from 'umi';





class UserIdleTimer extends Component {

  constructor(props) {
    super(props);




    this.state = {
      isModalVisible: false,
      logoutTimer: Date.now() + 10000
    }

    this.countdownRef = React.createRef();

    this.onPrompt = this.onPrompt.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.onActive = this.onActive.bind(this);
    this.onAction = this.onAction.bind(this);
    this.onStay = this.onStay.bind(this);
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.initialState !== this.props.initialState) {
      this.setState({
        initialState: this.props.initialState
      });
    }
  }

  onPrompt () {
    // Fire a Modal Prompt
  }

  onIdle () {
    const component = this;
    // Close Modal Prompt
    // Do some idle action like log out your user

    this.setState({
      isModalVisible: true,
      logoutTimer: Date.now() + 30000
    }, function(e) {
      component.countdownRef.current.start();
    })
  }

  onActive (event) {
    // Close Modal Prompt
    // Do some active action
  }

  onAction (event) {
    // Do something when a user triggers a watched event
  }

  onStay() {
    const component = this;
    this.setState({
      isModalVisible: false
    }, function (e) {
      component.countdownRef.current.stop();
    })
  }

  render() {

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    return (

      <>

      {userInfo &&
      <IdleTimerProvider
          timeout={1000 * 60}
          onPrompt={this.onPrompt}
          onIdle={this.onIdle}
          onActive={this.onActive}
          onAction={this.onAction}
        >


      <Modal zIndex={10000} title={false} closable={false} footer={false} visible={this.state.isModalVisible}>

          <Alert
            message={this.props.intl.formatMessage({id: 'useridletimer.message', defaultMessage: 'Ihre Sitzung wurde länger nicht genutzt.'})}
            description={this.props.intl.formatMessage({id: 'useridletimer.description', defaultMessage: 'Bedenken Sie, dass Sie das System aus Sicherheitsgründen nach 10 Minuten ohne Aktivität automatisch ausloggt und auf die Login-Seite weiterleitet.'})}
            type="warning"
            showIcon
          />

          <Divider />

          <div style={{display: 'none'}}>
            {this.state.isModalVisible &&
              <Countdown
                ref={this.countdownRef}
                onComplete={() => {
                  //window.location = '/user/login';
                  //history.push('/user/login')
                }}
                date={this.state.logoutTimer}
              />
            }
          </div>


          <Space>
            <Button type="success" onClick={(e) => this.onStay(e)}>{this.props.intl.formatMessage({id: 'useridletimer.button.success', defaultMessage: 'weitermachen'})}</Button>
            <Button type="danger" onClick={() => history.push('/user/login')}>{'Logout'}</Button>
          </Space>



      </Modal>

    </IdleTimerProvider>
    }
    </>
    );
  }
}

export default injectIntl(UserIdleTimer);
