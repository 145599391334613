import React, { useCallback, useState } from 'react';
import { LogoutOutlined, SettingOutlined, UserOutlined, SelectOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import { history, useModel, useIntl } from 'umi';
import { stringify } from 'querystring';
import HeaderDropdown from '../HeaderDropdown';
import styles from './index.less';
import { outLogin } from '@/services/ant-design-pro/api';
import AvatarSelectMenu from '@/components/AvatarSelect/AvatarSelectMenu';
import LoadingOverlay from 'react-loading-overlay';


/**
 * 退出登录，并且将当前的 url 保存
 */
const loginOut = async () => {
  await outLogin();
  const { query = {}, search, pathname } = history.location;
  const { redirect } = query; // Note: There may be security issues, please note

  if (window.location.pathname !== '/user/login' && !redirect) {
    history.replace({
      pathname: '/user/login',
      search: stringify({
        redirect: pathname + search,
      }),
    });
  }
};

const AvatarDropdown = ({ menu }) => {
  const { initialState, setInitialState } = useModel('@@initialState');


  const intl = useIntl();

  const { currentUser } = initialState;
  const { selectedCompany } = initialState;


  const onMenuClick = useCallback(
    (event) => {
      const { key } = event;

      if (key === 'logout') {

        setInitialState((s) => ({ ...s, currentUser: undefined }));
        loginOut();
        return;
      }

      if(key == 'companySelect') {
        return;
      }

      history.push(`/account/${key}`);
    },
    [setInitialState],
  );
  const loading = (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!initialState) {
    return loading;
  }

  if (!currentUser || !currentUser.user.name[0].value) {
    return loading;
  }


  const menuItems = [
      {
          key: 'companySelect',
          icon: <SelectOutlined />,
          label: <AvatarSelectMenu />,
      },
      {
          key: 'logout',
          icon: <LogoutOutlined />,
        label: intl.formatMessage({id: 'avatarSelect.logout', defaultMessage: 'Logout'}),
      }
  ];

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick} items={menuItems} />
  );
  return (
    <>
      <HeaderDropdown overlay={menuHeaderDropdown}>
        <span className={`${styles.action} ${styles.account}`}>
          <Avatar size="small" className={styles.avatar} src={currentUser.avatar} icon={<UserOutlined style={{color: '#000'}} />} alt="avatar" />
          <span className={`${styles.name} anticon`}>{(selectedCompany) ? selectedCompany.name : 'Logout'}</span>
        </span>
      </HeaderDropdown>
    </>
  );
};

export default AvatarDropdown;
