import { useIntl } from 'umi';
import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';
import SessionCountdown from './SessionCountdown';
import UserIdleTimer from './UserIdleTimer';
import { useModel } from 'umi';


export default () => {

  const { initialState, setInitialState } = useModel('@@initialState');
  const intl = useIntl();
  const currentYear = new Date().getFullYear();
  return (
    <>

    {initialState.currentUser &&
      <>
      <SessionCountdown initialState={initialState} />
      {/*<UserIdleTimer initialState={initialState} />*/}
      </>
    }


      <DefaultFooter
        copyright={`${currentYear} ${'Pensionskasse SBV - Sumatrastrasse 15 - CH-8006 Zürich - 044 258 84 50'}`}
        links={[
          {
            key: 'Impressum',
            title: intl.formatMessage({id: 'footer.imprint', defaultMessage: 'Impressum'}),
            href: 'https://www.pensionskasse-sbv.ch/de/impressum.html',
            blankTarget: true,
          },
          {
            key: 'Datenschutz',
            title: intl.formatMessage({id: 'footer.privacy', defaultMessage: 'Datenschutz'}),
            href: 'https://www.pensionskasse-sbv.ch/de/datenschutz.html',
            blankTarget: true,
          }
        ]}
      />
    </>
  );
};
