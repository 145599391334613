export default {
  'RGVyIEJlbnV0emVybmFtZSBvZGVyIGRhcyBQYXNzd29ydCBzaW5kIG5pY2h0IGtvcnJla3Qu': 'Il nome utente e la password non sono corretti.', //Der Benutzername oder das Passwort sind nicht korrekt.
  'RGVyIEJlbnV0emVyIGlzdCB1bmfDvGx0aWcu': 'L’utente non è valido.', //Der Benutzer ist ungültig.
  'RGVyIEFrdGl2aWVydW5nc2NvZGUgaXN0IHVuZ8O8bHRpZy4=': 'Il codice di attivazione non è valido.', //Der Aktivierungscode ist ungültig.
  'RGVyIFZlcmlmaXppZXJ1bmdzY29kZSBpc3QgdW5nw7xsdGlnLg==': 'Il codice di verifica non è valido.', //Der Verifizierungscode ist ungültig.
  'U2llIHd1cmRlbiBlcmZvbGdyZWljaCBhYmdlbWVsZGV0Lg==': 'Sei stato disconnesso con successo.', //Sie wurden erfolgreich abgemeldet.
  'SWhyZSBTaXR6dW5nIGlzdCBhYmdlbGF1ZmVuLg==': 'la tua sessione è scaduta', //Ihre Sitzung ist abgelaufen
  'U3RpY2hkYXR1bSBsaWVndCB2b3IgZGVtIERhdHVtIGRlciBsZXR6dGVuIE11dGF0aW9uLg0KV2VpdGVyPw==': 'La data di riferimento è precedente alla data dell’ultima mutazione.', //Stichdatum liegt vor dem Datum der letzten Mutation.

  'QWRyZXNzLU11dGF0aW9u': 'Modifica indirizzo', //Adress-Mutation
  'QXVzdHJpdHQ=': 'Uscita', //Austritt
  'TG9obi1NdXRhdGlvbg==': 'Modifica salario', //Lohn-Mutation
  'RWludHJpdHQ=': 'Entrata', //Eintritt
  'V2llZGVyZWludHJpdHQ=': 'RientroT', //Wiedereintritt
  'UGVyc29uYWxpZW4tTXV0YXRpb24=': 'Modifica dati personali', //Personalien-Mutation
  'QW5mcmFnZQ==': 'Richiesta', //Anfrage
  'RGF0ZWnDvGJlcnRyYWd1bmc=': 'Trasmissione file', //Dateiübertragung
  'QXJiZWl0c3VuZsOkaGlna2VpdA==': 'Incapacità al lavoro', //Arbeitsunfähigkeit
  'TG9obm1lbGR1bmc=': 'Déclaration de salaire', //Lohnmeldung



  'UmlzaWtvYmVpdHJhZyBBcmJlaXRnZWJlcg==': 'Contributo di rischio datore di lavoro	', //Risikobeitrag Arbeitgeber
  'UmlzaWtvYmVpdHJhZyBBcmJlaXRuZWhtZXI=': 'Contributo di rischio lavoratore', //Risikobeitrag Arbeitnehmer
  'U3BhcmJlaXRyYWcgQXJiZWl0Z2ViZXI=': 'Contributo di risparmio datore di lavoro', //Sparbeitrag Arbeitgeber
  'U3BhcmJlaXRyYWcgQXJiZWl0bmVobWVy': 'Contributo di risparmio lavoratore', //Sparbeitrag Arbeitnehmer

  'QmVpbSB2ZXJzZW5kZW4gZGVyIFNNUyBpc3QgZWluIEZlaGxlciBhdWZnZXRyZXRlbi4gQml0dGUga29udGFrdGllcmVuIFNpZSBkZW4gU3VwcG9ydC4=': 'C è stato un errore nel tentativo di inviare il messaggio. Per ulteriori informazioni, contattare il supporto.' // Beim Versenden der SMS ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Support für weitere Informationen.

};
