// @ts-nocheck

  import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import BankOutlined from '@ant-design/icons/es/icons/BankOutlined';
import UnorderedListOutlined from '@ant-design/icons/es/icons/UnorderedListOutlined'
  export default {
    HomeOutlined,
UserOutlined,
BankOutlined,
UnorderedListOutlined
  }