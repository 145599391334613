import { Component } from 'react';
import countriesJson from 'country-region-data/data.json';
import Logger from '@/helper/Logger';
import { injectIntl, history } from 'umi';
import { stringify } from 'querystring';
import moment from 'moment';
import { EncryptStorage } from 'encrypt-storage';

import de from '@/locales/de-DE';
import fr from '@/locales/fr-FR';
import it from '@/locales/it-IT';

const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: '@portal',
});

class Helper extends Component {
  constructor(props) {
    super(props);
  }

  static renameVariableKeys(object) {
    const newObject = object.map(({
      text: label,
      ...rest
    }) => ({
      label,
      ...rest
    }));

    return newObject;
  }

  static renameReglementKeys(object) {
    let newObject = object.filter(item => item && !item.reglementId.toString().includes("99"));

    newObject = newObject.map(({
      reglementId: value,
      reglementsbezeichnung: label,
      ...rest
    }) => ({
      label,
      value,
      ...rest
    }));

    return newObject;
  }

  static renamePersonalKategorieKeys(object) {

    let newObject = object.map(({
      value: label,
      ...rest
    }) => ({
      label,
      ...rest
    }));

    newObject = newObject.map(({
      key: value,
      ...rest
    }) => ({
      value,
      ...rest
    }));


    return newObject;
  }

  static renameVersicherteKeys(object) {
    let selectOptions = new Array();

    const variables = JSON.parse(localStorage.getItem('variables'));

    for (let item of Object.entries(object)) {
      item[1].sozversId = item[1].sozversId.replace(/^(\d{3})(\d{4})(\d{4})(\d{2})$/g, '$1.$2.$3.$4');

      let reglementIds = item[1].reglementIds;

      if(item[1].pendenterAustritt && item[1].pendenterAustritt.length > 0) {
        reglementIds = Helper.getReglementIds();
      }

      let selectedReglement = item[1].selectedReglement;

      let option = {label: item[1].vorname + ' ' + item[1].nachname + ' (' + item[1].sozversId + ')', value: item[1].versintId, reglementIds: reglementIds, selectedReglement: selectedReglement};

      let addOption = true;

      // filter admissions from select otions list
      if(item[1]['pendenterAustritt']) {
        let exitDate = item[1]['pendenterAustritt'][0]['austrittsdatum'];
        if(moment(exitDate).isBefore()) {
          //addOption = false;
        }
        addOption = false;
      }


      if(addOption) {
        selectOptions.push(option);
      }
    }

    return selectOptions;
  }

  static renameVersicherteAdmissionKeys(object) {
    let selectOptions = new Array();

    const variables = JSON.parse(localStorage.getItem('variables'));

    for (let item of Object.entries(object)) {

      item[1].sozversId = item[1].sozversId.replace(/^(\d{3})(\d{4})(\d{4})(\d{2})$/g, '$1.$2.$3.$4');

      let reglementIds = item[1].reglementIds;

      if(item[1].pendenterAustritt && item[1].pendenterAustritt.length > 0) {
        reglementIds = Helper.getReglementIds();
      }

      let selectedReglement = item[1].selectedReglement;

      let option = {label: item[1].vorname + ' ' + item[1].nachname + ' (' + item[1].sozversId + ')', value: item[1].versintId, reglementIds: reglementIds, selectedReglement: selectedReglement, resetVersId: item[1].resetVersId};

      let addOption = true;

      if(addOption) {
        selectOptions.push(option);
      }
    }

    return selectOptions;
  }

  static getReglementByIds(reglementIds) {

    const variables = JSON.parse(localStorage.getItem('variables'));

    const reglementOptions = reglementIds.map(reglementId => {
      let reglementOption = variables.Reglement.find((obj) => {
        return reglementId === obj.reglementId;
      });


      if(reglementOption) {
        return reglementOption;
      }
    })
    return Helper.renameReglementKeys(reglementOptions);
  }

  static getReglementIds() {

    const variables = JSON.parse(localStorage.getItem('variables'));

    const reglementIds = variables.Reglement.map(reglement => {
      return reglement.reglementId;
    })
    return reglementIds;
  }

  static getPersonalKategorieById(reglementId, personalKategorieId) {
    const variables = JSON.parse(localStorage.getItem('variables'));

    const selectedReglement = variables.Reglement.find((obj) => {
      return obj.reglementId === reglementId;
    });

    if(selectedReglement) {
      const personalKategorie = selectedReglement['PersonalKategorie'].find((obj) => {
        return obj.key === personalKategorieId;
      });
      return (personalKategorie) ? personalKategorie['value'] : false;
    } else {
      return false;
    }


  }

  static renameFilterKey(object) {
    const newObject = object.map(({
      label: text,
      ...rest
    }) => ({
      text,
      ...rest
    }));

    return newObject;
  }

  static clearLocalStorage() {
    let currentLang = localStorage.getItem('umi_locale');
    let contributionTableState = localStorage.getItem('pro-table-contribution');
    let wageTableState = localStorage.getItem('pro-table-wage');
    let insurantTableState = localStorage.getItem('pro-table-insurant');
    localStorage.clear();


    localStorage.setItem('umi_locale', currentLang);
    localStorage.setItem('pro-table-contribution', (contributionTableState) ? contributionTableState : {});
    localStorage.setItem('pro-table-wage', (wageTableState) ? wageTableState : {});
    localStorage.setItem('pro-table-insurant', (insurantTableState) ? insurantTableState : {});
  }

  static getCountries() {

    let countriesJsonArray;

    const regionNames = new Intl.DisplayNames(
      [Helper.getCurrentLang()], {type: 'region'}
    );



    countriesJsonArray = countriesJson.map(item => {
      const obj = Object.assign({}, item);
      obj['countryName'] =regionNames.of(item.countryShortCode);
      return obj;
    });


    const countriesJsonNew = countriesJsonArray.map(({
      countryName: label,
      countryShortCode: value,
      ...rest
    }) => ({
      label,
      value,
      ...rest
    }));

    Logger.logAction(countriesJsonNew, 'getCountries', 'info', '');

    return countriesJsonNew;

  }

  static getCountryByValue(value) {
    const country = Helper.getCountries().find((obj) => {
      return obj.value === value;
    });
    return (country) ? country['label'] : '';
  }

  static getAccessToken() {
    let userInfo = localStorage.getItem('userInfo');

    if(userInfo) {
      let jwtToken = JSON.parse(userInfo).access_token;
      return jwtToken;
    } else {
      return false;
    }
  }

  static getCurrentLang() {
    let currentLang = localStorage.getItem('umi_locale');
    currentLang = currentLang.split('-');

    return currentLang[0];
  }

  static getVariables() {
    const variables = localStorage.getItem('variables');

    //console.log(variables);

    if (variables !== null) {
      return JSON.parse(variables);
    } else {
      if (history.location.pathname !== "/user/login") {
        window.location.href = "/user/login";
      }
    }
  }


  static getOrgVariables() {
    const variables = localStorage.getItem('variablesOrg');

    //console.log(variables);

    if (variables !== null) {
      return JSON.parse(variables);
    } else {
      if (history.location.pathname !== "/user/login") {
        window.location.href = "/user/login";
      }

    }
  }

  static translateVariables(variables) {
    let pksVariables = variables;
    let variableKeys = Object.keys(pksVariables);
    //console.log(variableKeys);
    let variableValues = Object.values(pksVariables);
    //console.log(variableValues);


    for (let index = 0; index < variableValues.length; ++index) {
        const element = variableValues[index];
        let variableKey = variableKeys[index];
        //console.log(variableKeys[index]);
        //console.log(pksVariables[variableKey]);

        if(element[0] && element[0].hasOwnProperty('text')) {
          //console.log(element);
          let translatedItem = element.map(item => ({ ...item, text: Helper.translateString(item.text) }));
          pksVariables[variableKey] = translatedItem;
        }

        if(element[0] && element[0].hasOwnProperty('reglementsbezeichnung')) {
          //console.log(element);
          let translatedItem = element.map(item => ({ ...item, reglementsbezeichnung: Helper.translateString(item.reglementsbezeichnung) }));

          //console.log(translatedItem);

          for (let indexPersonalKategorie = 0; indexPersonalKategorie < translatedItem.length; ++indexPersonalKategorie) {
            const reglementItem = translatedItem[indexPersonalKategorie];
            //console.log(reglementItem);
            if(reglementItem.PersonalKategorie) {
              let translatedItemPersonalKategorie = reglementItem.PersonalKategorie.map(item => ({ ...item, value: Helper.translateString(item.value) }));
              reglementItem.PersonalKategorie = translatedItemPersonalKategorie;
              translatedItem[indexPersonalKategorie] = reglementItem;
            }

          }


          pksVariables[variableKey] = translatedItem;
        }

    }

    /*variableValues.forEach((variableItem, index) => {
      let translatedItem = variableItem.map(item => ({ ...item, text: Helper.translateString(item.text) }));
      console.log(translatedItem);
      //pksVariables[variableKeys[index]] = translatedItem;

    });*/

    //console.log(pksVariables);

    return pksVariables;
  }


  static translateString(str) {



    const localeInfo = {
      'de-DE': {
        messages: {
          ...de
        },
        locale: 'de-DE',
      },
      'fr-FR': {
        messages: {
          ...fr
        },
        locale: 'fr-FR',
      },
      'it-IT': {
        messages: {
          ...it
        },
        locale: 'it-IT',
      },
    };

    let translatedMessage = str;


    if(translatedMessage) {
      let dateRegexG = /[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}/g;
      let dateFound = translatedMessage.match(dateRegexG);
      if(dateFound) {
        translatedMessage = translatedMessage.replace(dateFound[0], '{date}');
      }
    }


    let messages = {
      'The recovery password is not valid.': 'Der Aktivierungscode ist ungültig.',
      'This User was not found or invalid': 'Der Benutzer ist ungültig.',
      'Sorry, unrecognized username or password.': 'Der Benutzername oder das Passwort sind nicht korrekt.',
      'The user has not been activated or is blocked.': 'Der Benutzer wurde nicht aktiviert oder ist gesperrt.'
    };

    if(messages.hasOwnProperty(translatedMessage)) {
      translatedMessage = messages[translatedMessage];

      if(localStorage.getItem('umi_locale') == 'de-DE') {
        return translatedMessage;
      }
    }


    if(localStorage.getItem('umi_locale') == 'de-DE') {
      return str;
    }



    let translatedMessageId = window.btoa(unescape(encodeURIComponent(translatedMessage)));
    //console.log(translatedMessageId);
    //console.log(str);

    translatedMessage = (localeInfo[localStorage.getItem('umi_locale')].messages[translatedMessageId]) ? localeInfo[localStorage.getItem('umi_locale')].messages[translatedMessageId] : translatedMessage;

    return translatedMessage;
  }



  static getCurrentUser() {
    let currentUser = localStorage.getItem('userInfo');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser.current_user;
    return currentUser;
  }


  static getSelectedCompany() {
    let selectedCompany = localStorage.getItem('selectedCompany');
    selectedCompany = JSON.parse(selectedCompany);
    return selectedCompany;
  }

  static getCompanyCollection() {
    let companyCollection = localStorage.getItem('companyCollection');
    companyCollection = JSON.parse(companyCollection);
    return companyCollection;
  }


  static setEncryptItem(key, value) {
    encryptStorage.setItem(key, value);
  }

  static getEncryptItem(key) {
    let item = encryptStorage.getItem(key, value);
    return item;
  }






}

export default injectIntl(Helper);
