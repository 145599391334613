export default {
  'dashboard.welcome': 'Benvenuto/a {firstname} {lastname}', //Herzlich willkommen {firstname} {lastname}
  'dashboard.welcome01': 'sul Portale del datore di lavoro della Cassa pensioni SSIC.', //auf dem Arbeitgeberportal der Pensionskasse SBV.
  'dashboard.card.title01': 'PK Schweizerischer Baumeisterveraband', //PK Schweizerischer Baumeisterveraban
  'dashboard.card.title02': 'Shortcut', //Shortcuts
  'dashboard.card.title03': 'Attività', //Aktivitäten
  'dashboard.address.title01': 'professionale', //Geschäftsadresse
  'dashboard.address.title02': 'postale', //Postfachadresse
  'dashboard.address.title03': 'Contatto', //Kontakt
  'dashboard.address.title04': '(Lun-Ven)', //Bürozeiten (Mo-Fr)
  'dashboard.clock': 'in punto', //Uhr
  'dashboard.button.contact': 'Inoltrare richiesta', //Anfrage stellen
};
