export default {
  'form.textPlaceholder': 'Entrer une valeur', // Bitte eingeben
  'form.required': 'Champ obligatoire', // Pflichtfeld
  'form.email': 'Adresse e-mail', // E-Mail Adresse
  'form.email.validate': 'Adresse électronique non valable', // Keine gültige E-Mail Adresse
  'form.password': 'Mot de passe', // Passwort
  'form.firstname': 'Prénom', // Vorname
  'form.lastname': 'Nom de famille', // Nachname
  'form.phone': 'Téléphone', // Telefon
  'form.companyNumber': 'Numéro d’entreprise', // Firmennummer
  'form.languageID': 'Langue', // Sprache
  'form.verificationCode': 'Code de vérification', // Verifizierungscode
  'form.activationCode': 'Code d’activation', // Aktivierungscode
  'form.newPassword': 'Nouveau mot de passe', // Neues Passwort
  'form.newPasswordConfirm': 'Confirmer le nouveau mot de passe', //Neues Passwort bestätigen
  'form.newPassword.error': 'Les mots de passe ne concordent pas.', // Die Passwörter stimmen nicht überein.
  'form.yes': 'Oui', // Ja
  'form.no': 'Non', // Nein
  'form.validFrom': 'Valable dès', // Gültig ab
  'form.contact.title': 'Émettre une demande de renseignements', // Anfrage stellen
  'form.contact.subject': 'Objet', // Betreff
  'form.contact.message': 'Votre message', // Ihre Nachricht
  'form.contact.subject01': 'Demande d’ordre général', // Allgemeine Anfrage
  'form.contact.subject02': 'Assistance', // Support
  'form.contact.subject03': 'Règlement Devis', // Reglement Offerte
  'form.contact.success': 'Votre demande de renseignements a été envoyée.', // Ihre Anfrage wurde verschickt.
};
