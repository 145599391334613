export default {

  'insurants.Postadresse': 'Adresse postale', // Postadresse

  'insurants.entry.title': 'Entrée', // Eintritt
  'insurants.entry.create': 'Saisir l’entrée', // Eintritt erfassen
  'insurants.exit.title': 'Départ', // Austritt
  'insurants.exit.create': 'Saisir départ', // Austritt erfassen
  'insurants.disability.title': 'Incapacité de travail', // Arbeitsunfähigkeit
  'insurants.disability.create': 'Saisir l’incapacité de travail', // Arbeitsunfähigkeit erfassen
  'insurants.geburtsdatum': 'Date de naissance', // Geburtsdatum
  'insurants.sozversId': 'Numéro de sécurité sociale', // Sozialversicherungsnummer
  'insurants.versId': 'ID d’assuré-e', // Versicherten-ID
  'insurants.exit.tooltip': 'Départ en attente {date}', // Pendenter Austritt
  'insurants.status.exit': 'Départ en attente', // Pendenter Austritt
  'insurants.status.active': 'Actif', // Aktiv
  'insurants.button.details': 'Détails', // Details
  'insurants.person.button.edit': 'Traiter les coordonnées personnelles', // Personalien bearbeiten
  'insurants.address.button.add': 'Saisir l’adresse', // Adresse erfassen
  'insurants.reentry': 'Réentrée', // Wiedereintritt
  'insurants.persons.tab.title': 'Coordonnées personnelles', // Personalien

  'insurants.form.cancelText': 'Voulez-vous vraiment quitter le traitement? Vos données seront perdues.', // Wollen Sie die Bearbeitung wirklich verlassen? Ihre Daten gehen verloren.
  'insurants.form.cancel.confirm': 'Confirmer', // Bestätigen
  'insurants.form.cancel.cancel': 'Annuler', // Abbrechen

  'insurants.form.save': 'Enregistrer et suivant', // Speichern & weiter
  'insurants.form.finish': 'Achever l’entrée', // Eintritt abschliessen
  'insurants.form.back': 'précédent', // zurück

  'insurants.form.step.uebersicht.title': 'Aperçu', // Übersicht

  'insurants.form.Reglemente': 'Règlements', // Reglemente
  'insurants.form.zivilstand.label': 'Etat civil', // Zivilstand
  'insurants.form.sozId.label': 'Numéro de sécurité sociale', // Numéro de sécurité sociale
  'insurants.form.zivilstand.label.valid': 'Etat civil, valable à partir de', // Zivilstand, gültig ab
  'insurants.form.zivilstandGueltigAb.label': 'Etat civil valable à partir de', // Zivilstand, gültig ab
  'insurants.form.spracheId.label': 'Langue de correspondance', // Korrespondenzsprache

  'insurants.form.step.start.title': 'Démarrage', // Start
  'insurants.form.step.type.label': 'Type d’entrée', // Art des Eintritts
  'insurants.form.step.type.option01': 'Entrée', // Eintritt
  'insurants.form.step.type.option02': 'Réentrée', // Wiedereintritt

  'insurants.form.step.person.title': 'Coordonnées personnelles', // Personalien
  'insurants.form.step.insurant.label': 'Assuré', // Versicherter
  'insurants.form.anrede.label': 'Civilités', // Anrede
  'insurants.form.vorname.label': 'Prénom', // Vorname
  'insurants.form.nachname.label': 'Nom de famille', // Nachname
  'insurants.form.geburtsdatum.label': 'Date de naissance', // Geburtsdatum
  'insurants.form.geburtsdatum.minimum': 'Age minimum 17 ans', // Geburtsdatum
  'insurants.form.sozversId.label': 'Numéro de sécurité sociale', // Sozialversicherungsnummer
  'insurants.form.sozversId.invalid': 'Numéro de sécurité sociale invalide', // Sozialversicherungsnummer ungültig



  'insurants.form.step.address.title': 'Adresse', // Adresse
  'insurants.form.step.address.search.placeholder': 'Entrez la rue et le numéro pour rechercher et remplir l’adresse automatiquement',
  'insurants.form.step.address.search.label': 'adresse de recherche',
  'insurants.form.adresszeile2.label': 'Rue + n°', // Strasse + Nr.
  'insurants.form.plz.label': 'NPA', // Plz
  'insurants.form.wohnort.label': 'Lieu', // Ort
  'insurants.form.adresszeile1.label': 'Complément d’adresse', // Adresszusatz
  'insurants.form.land.label': 'Pays', // Land
  'insurants.form.Kontaktdaten': 'Coordonnées de contact', // Kontaktdaten
  'insurants.form.telefon.label': 'Téléphone', // Telefon
  'insurants.form.email.invalid': 'pas d’adresse e-mail valide',
  'insurants.form.email.label': 'Adresse e-mail', // E-Mail Adresse
  'insurants.form.address.label': 'Adresse', // Adresse



  'insurants.form.step.wage.title': 'Données salariales', // Lohndaten
  'insurants.form.gueltigAb.label': 'Date d’entrée', // Eintrittsdatum
  'insurants.form.gueltigAb.minimum': 'Première entrée 01.01.2021', // Frühester Eintritt 01.01.2021
  'insurants.form.beschaeftigungsGrad.label': 'Taux d’activité (%)', // Pensum (%)
  'insurants.form.betrag.label': 'Salaire annuel (CHF)', // Jahreslohn (CHF)
  'insurants.form.Reglement': 'Règlement', // Reglement
  'insurants.form.personalKategorie.label': 'Catégorie de salaire', // Lohnkategorie


  'insurants.form.personData.empty': '',
  'insurants.form.personData.card.title': 'Coordonnées personnelles', // Personalien
  'insurants.form.address.card.title': 'Adresse et contact', // Adresse & Kontakt
  'insurants.form.addressData.empty': '',
  'insurants.form.wage.card.title': 'Données salariales', // Lohndaten
  'insurants.form.wageData.empty': '',




  'insurants.form.exit.message.success': 'La notification de sortie a été envoyée avec succès.',
  'insurants.form.exit.gueltigAb.label': 'Date de départ', // Austrittsdatum
  'insurants.form.exit.austrittsgrundId.label': 'Motif de départ', // Austrittsgrund




  'insurants.form.disability.create.label': 'Saisir l’incapacité de travail', // Arbeitsunfähigkeit erfassen
  'insurants.form.disability.type.label': 'Nature de l’incapacité de travail', // Art der Arbeitsunfähigkeit
  'insurants.form.disability.type.Krankheit': 'Maladie', // Krankheit
  'insurants.form.disability.type.Unfall': 'Accident', // Unfall
  'insurants.form.disability.disability_percent.label': 'Temps de travail non effectué %', // Arbeitsausfall (%)
  'insurants.form.disability.date.label': 'En incapacité de travail depuis', // Arbeitsunfähig seit
  'insurants.form.disability.address_name.label': 'Nom et adresse de l’assurance indemnités journalières maladie et/ou de l’assurance accident', // Name und Adresse der Krankentaggeld- bzw. Unfallversicherung
  'insurants.form.disability.address.card.title': 'Informations complémentaires requises', // Zusätzlich benötigte Informationen
  'insurants.form.disability.txt01': 'Veuillez joindre à la demande les documents suivants', // Bitte legen Sie dem Antrag folgende Dokumente bei
  'insurants.form.disability.txt02': 'Carte d’indemnité journalière', // Taggeldkarte
  'insurants.form.disability.txt03': 'Décomptes existants des indemnités journalières maladie', // vorhandene Krankentaggeldabrechnungen
  'insurants.form.disability.txt04': 'Décompte final des indemnités journalières', // vorhandene Krankentaggeldabrechnungen
  'insurants.form.disability.txt05': 'Certificats médicaux', // Arztzeugnisse
  'insurants.form.disability.txt06': 'Formulaire d’inscription AI (si présent)', // IV-Anmeldeformular (wenn vorhanden)
  'insurants.form.disability.txt07': 'Formulaire AI pour l’employeur (si présent)', // IV-Formular für den Arbeitgeber(wenn vorhanden)
  'insurants.form.disability.txt08': 'Disposition AI (si présente)', // IV-Verfügung(wenn vorhanden)
  'insurants.form.disability.file.label': 'Annexes', // Anhänge
  'insurants.form.disability.file.invalid': 'Limite de fichier de 21 Mo dépassée.',



  'insurants.form.wage.exit.message': 'Une sortie a déjà été enregistrée pour cette personne. Pour un changement de salaire après la date de départ, nous vous demandons d’utiliser la fonction de rentrée.',
  'insurants.form.wage.gueltigAb.label': 'Valable dès', // Gültig ab
  'insurants.form.wage.gueltigBis.label': 'Valide jusqu’au', // Gültig Bis
  'insurants.wage.selected.count': 'Sélectionné', //Ausgewählt:



  'insurants.details.wage.card.title': 'Salaires', // Löhne
  'insurants.form.wage.edit.title': 'modifier payer',
  'insurants.form.wage.add.title': 'Saisir le salaire', // Lohn erfassen


  'insurants.contribution.card.title': 'Cotisations mensuelles', // Monatsbeiträge
  'insurants.contribution.beitragstyp.label': 'Type de cotisation	', // Beitragstyp
  'insurants.contribution.betrag.label': 'Montant', // Betrag
  'insurants.contribution.betragAG.label': 'Cotisation annuelle de l’employeur (CHF)', // Jahresbeitrag Arbeitgeber (CHF)
  'insurants.contribution.betragAN.label': 'Cotisation annuelle du salarié (CHF)', // Jahresbeitrag Arbeitnehmer (CHF)
  'insurants.contribution.total.label': 'Total cotisation annuelle (CHF)', // Total Jahresbeitrag (CHF)
  'insurants.contribution.month.title': 'Cotisations mensuelles', // Monatsbeiträge


  'insurants.form.address.edit': 'Modifier l’adresse',

  'insurants.tabs.Unterlagen.label': 'Documents', // Unterlagen
  'insurants.upload.message.title': 'Télécharger des documents vers le système', // Dokumente hochladen
  'insurants.upload.message': 'Veuillez indiquer dans le champ de message le but des documents transmis.', // Bitte im Nachrichtenfeld den Zweck der übermittelten Unterlagen eintragen.


  'insurant.form.edit.message.success': 'Vos modifications ont été enregistrées avec succès.',
  'insurants.address.empty': 'Aucune adresse disponible',


  'insurants.form.exit.edit.title': 'Sortie de masse',
  'exit.report.success': 'La sortie de masse est terminée. Maintenant vous pouvez fermer la fenêtre. En cas d éventuelles erreurs, veuillez nous contacter.',
  'exit.report.pending': 'Veuillez attendre que tous les sorties aient été transférés.',


};
