export default {
  'wages.title': 'Salaire', // Löhne
  'wages.create': 'Saisir le salaire', // Lohn erfassen
  'wages.report': 'Déclaration de salaire', // Lohnmeldung
  'wages.report.stepTitle01': 'Renseignements', // Angaben
  'wages.report.stepTitle02': 'Salaires', // Löhne
  'wages.report.txt01': 'Merci de répondre aux questions ci-dessous.', // Bitte beantworten Sie die untenstehenden Fragen.
  'wages.report.txt02': 'En cas de problèmes, notre équipe de la Caisse de pension vous aidera volontiers: 044 258 84 50', // Bei Problemen hilft Ihnen unser Pensionskassenteam gerne weiter: 044 258 84 50
  'wages.report.txt03': 'Renseignements au sujet de la prévoyance professionnelle pour l’exercice commercial', // Angaben über die berufliche Vorsorge für das Geschäftsjahr
  'wages.report.txt04': 'Afin que notre fondation puisse faire valoir une éventuelle subvention pour {year}, au titre d’une structure d’âges désavantageuse, nous avons besoin des renseignements suivants de votre part.', // Damit unsere Stiftung einen allfälligen Zuschuss für das Jahr 2023 beim Sicherheitsfond wegen ungünstiger Altersstruktur geltend machen kann, benötigen wir von Ihnen folgende Angaben.
  'wages.report.question01': '1. Existe-t-il, pour l’entreprise membre, des contrats d’adhésion pour l’assurance de base (conformément à la LPP) auprès de plusieurs institutions de prévoyance?', // 1. Bestehen für die Mitgliedfirma Anschlussverträge für die Grundversicherung (gemäss BVG) bei mehreren Vorsorgeeinrichtungen?
  'wages.report.question02': '2. Le propriétaire de l’entreprise affiliée a-t-il un statut d’indépendant ou d’indépendant avec propres employés?', // 2. Gilt der Inhaber der Mitgliedfirma als Selbständigerwerbend oder Selbständigererwerbend mit eigenen Angestellten?
  'wages.report.confirmation': 'Je confirme par la présente l’exactitude de mes données.', // Hiermit bestätige ich die Richtigkeit meiner Daten.
  'wages.report.address': 'Nom/Adresse de l’institution de prévoyance', // Name/Adresse der Vorsorgeeinrichtung
  'wages.report.success': 'La mutation de masse est terminée. Vous pouvez maintenant fermer la fenêtre. S’il y a des erreurs, veuillez nous contacter.', //Die Massenmutation ist abgeschlossen. Sie können das Fenster nun schliessen. Bei allfälligen Fehleren kontaktieren Sie uns bitte.
  'wages.report.alert.success': 'Votre rapport de salaire a été envoyé.',
  'wages.report.pending': 'Veuillez attendre que les salaires soient tous transmis.', // Bitte warten Sie bis alle Löhne übertragen wurden.
  'wages.personalkategorie': 'Catégorie de salaire', // Lohnkategorie
  'wages.beschaeftigungsgrad': 'Taux d’activité', // Pensum (%)
  'wages.betrag': 'Salaire annuel', // Jahreslohn
  'wages.status': 'statut', // Status
  'wages.report.button.next.label': 'Continuer', // Weiter
  'wages.signature.reset': 'Réinitialiser', // Zurücksetzen
  'wages.signature.fullname': 'Prénom Nom', // Vorname & Name
  'wages.signature.label': 'Signature', // Unterschrift
  'wages.signature.alert': 'Veuillez saisir votre nom et prénom dans le champ ci-dessous et signer directement dans le champ', // Bitte tragen Sie im untenstehenden Feld Ihren Vor- und Nachnamen ein und unterschreiben Sie direkt im Feld

};
