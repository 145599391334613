import { useModel, useIntl } from 'umi';

import Logo from './assets/images/pensionskasse-sbv-logo-weiss.svg';
import LogoFR from './assets/images/pensionskasse-sbv-logo-weiss-fr.svg';
import LogoIT from './assets/images/pensionskasse-sbv-logo-weiss-it.svg';

import './assets/css/index.css';


export default () => {

  const intl = useIntl();
  const currentLang = localStorage.getItem('umi_locale');

  return (
    <>
      <div className="ant-pro-global-header-logo">
        <a>
          <img src={(currentLang === 'de-DE') ? Logo : (currentLang === 'it-IT') ? LogoIT : LogoFR} alt="logo" />
          <h1>{intl.formatMessage({id: 'app.settings.title', defaultMessage: 'Kundenportal'})}</h1>
        </a>
      </div>
    </>
  );
};
