// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: '.umi__plugin-layout__Layout' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/.umi/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "name": "Login",
            "path": "/user/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/user/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": "home",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/Dashboard'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/insurants",
        "name": "Versicherte",
        "icon": "user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Insurant' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/Insurant'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/wages",
        "name": "Löhne",
        "icon": "bank",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Wage' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/Wage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/contribution",
        "name": "Beiträge",
        "icon": "unordered-list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contribution' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/Contribution'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/index.html",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/dashboard",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/var/www/vhosts/frontend.portal.pensionskasse-sbv.ch/dev.frontend.portal.pensionskasse-sbv.ch/application/portal/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
