export default {
  'RGVyIEJlbnV0emVybmFtZSBvZGVyIGRhcyBQYXNzd29ydCBzaW5kIG5pY2h0IGtvcnJla3Qu': 'Nom d’utilisateur ou mot de passe incorrect.', //Der Benutzername oder das Passwort sind nicht korrekt.
  'RGVyIEJlbnV0emVyIGlzdCB1bmfDvGx0aWcu': 'L’utilisateur n’est pas valable.', // Der Benutzer ist ungültig.
  'RGVyIEFrdGl2aWVydW5nc2NvZGUgaXN0IHVuZ8O8bHRpZy4=': 'Le code d’activation est invalide.', //Der Aktivierungscode ist ungültig.
  'RGVyIFZlcmlmaXppZXJ1bmdzY29kZSBpc3QgdW5nw7xsdGlnLg==': 'Le code de vérification est invalide.', //Der Verifizierungscode ist ungültig.
  'U2llIHd1cmRlbiBlcmZvbGdyZWljaCBhYmdlbWVsZGV0Lg==': 'Vous avez été déconnecté avec succès.', //Sie wurden erfolgreich abgemeldet.
  'SWhyZSBTaXR6dW5nIGlzdCBhYmdlbGF1ZmVuLg==': 'Votre session a expiré.', // Ihre Sitzung ist abgelaufen
  'U3RpY2hkYXR1bSBsaWVndCB2b3IgZGVtIERhdHVtIGRlciBsZXR6dGVuIE11dGF0aW9uLg0KV2VpdGVyPw==': 'La date de référence est antérieure à la date de la dernière mutation.', // Stichdatum liegt vor dem Datum der letzten Mutation.

  'QWRyZXNzLU11dGF0aW9u': 'Mutation d’adresse', // Adress-Mutation
  'QXVzdHJpdHQ=': 'Départ', // Austritt
  'TG9obi1NdXRhdGlvbg==': 'Mutation de salaire', // Lohn-Mutation
  'RWludHJpdHQ=': 'Entrée', // Eintritt
  'V2llZGVyZWludHJpdHQ=': 'Réentrée', // Wiedereintritt
  'UGVyc29uYWxpZW4tTXV0YXRpb24=': 'Mutation des coordonnées personnelles', // Personalien-Mutation
  'QW5mcmFnZQ==': 'Demande de renseignements', // Anfrage
  'RGF0ZWnDvGJlcnRyYWd1bmc=': 'Transmission de fichier', // Dateiübertragung
  'QXJiZWl0c3VuZsOkaGlna2VpdA==': 'Incapacité de travail', // Arbeitsunfähigkeit
  'TG9obm1lbGR1bmc=': 'Déclaration de salaire', // Lohnmeldung



  'UmlzaWtvYmVpdHJhZyBBcmJlaXRnZWJlcg==': 'Cotisation de risque employeur', // Risikobeitrag Arbeitgeber
  'UmlzaWtvYmVpdHJhZyBBcmJlaXRuZWhtZXI=': 'Cotisation de risque salarié', // Risikobeitrag Arbeitnehmer
  'U3BhcmJlaXRyYWcgQXJiZWl0Z2ViZXI=': 'Cotisation d’épargne employeur', // Sparbeitrag Arbeitgeber
  'U3BhcmJlaXRyYWcgQXJiZWl0bmVobWVy': 'Cotisation d’épargne salarié', // Sparbeitrag Arbeitnehmer

  'QmVpbSB2ZXJzZW5kZW4gZGVyIFNNUyBpc3QgZWluIEZlaGxlciBhdWZnZXRyZXRlbi4gQml0dGUga29udGFrdGllcmVuIFNpZSBkZW4gU3VwcG9ydC4=': 'Une erreur est survenue durant l envoi de votre SMS. Veuillez contacter notre support pour plus d informations.' // Beim Versenden der SMS ist ein Fehler aufgetreten. Bitte kontaktieren Sie unseren Support für weitere Informationen.

};
