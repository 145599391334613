import login from './fr-FR/login';
import form from './fr-FR/form';
import translatedMessages from './fr-FR/translatedMessages';
import footer from './fr-FR/footer';
import menu from './fr-FR/menu';
import avatarSelect from './fr-FR/avatarSelect';
import settings from './fr-FR/settings';
import dashboard from './fr-FR/dashboard';
import insurants from './fr-FR/insurants';
import wages from './fr-FR/wages';
import activity from './fr-FR/activity';
import variables from './fr-FR/variables';


export default {
  ...login,
  ...form,
  ...translatedMessages,
  ...footer,
  ...menu,
  ...avatarSelect,
  ...settings,
  ...dashboard,
  ...insurants,
  ...wages,
  ...activity,
  ...variables,
  'logout.success': 'Vous avez été déconnecté avec succès.', // Du hast dich erfolgreich abgemeldet.
  'loading': 'S’il vous plaît, attendez...', // Bitte warten
};
