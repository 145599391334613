export default {
  'SGVycg==': 'Monsieur', // Herr
  'RnJhdQ==': 'Madame', // Frau
  'RmlybWE=': 'Firma', // Firma

  'bGVkaWc=': 'célibataire', // ledig
  'dmVyaGVpcmF0ZXQ=': 'marié-e', // verheiratet
  'Z2VzY2hpZWRlbg==': 'divorcé-e', // geschieden
  'dmVyd2l0d2V0': 'veuf/veuve', // verwitwet
  'Z2VzZXR6bGljaCBnZXRyZW5udA==': 'légalement séparé-e', // gesetzlich getrennt
  'ZWluZ2V0ci4gUGFydG5lcnNjaGFmdA==': 'Partenariat enregistré', // eingetr. Partnerschaft
  'YXVmZ2VsLiBQYXJ0bmVyc2NoYWZ0': 'Partenariat dissous', // aufgel. Partnerschaft

  'U3RhbW1wZXJzb25hbA==': 'Stammpersonal', // Stammpersonal

  'QXVzdHJpdHQ=': 'Départ', // Austritt
  'VG9k': 'Décès', // Tod
  'UGVuc2lvbmllcnVuZw==': 'Départ à la retraite', // Pensionierung
  'RkFSIG1pdCBXZWl0ZXJ2ZXJzaWNoZXJ1bmc=': 'FRA avec continuation de l’assurance', // FAR mit Weiterversicherung
  'RkFSIG9obmUgV2VpdGVydmVyc2ljaGVydW5n': 'FRA sans continuation de l’assurance', // FAR ohne Weiterversicherung
  'U3RpbGxsZWd1bmcgVmVyc2ljaGVydW5n': 'Arrêt de l’assurance', // Stilllegung Versicherung
  'S8O8bmRpZ3VuZyBBRyAoQWx0ZXIgNTgp': 'Licenciement par l’employeur (âge 58 ans)', // Kündigung AG (Alter 58)
  'QXJiZWl0c3VuZsOkaGlnIGJlaSBBdXN0cml0dA==': 'En incapacité de travail lors du départ', // Arbeitsunfähig bei Austritt

  'RGV1dHNjaA==': 'Allemand', // Deutsch
  'RnJhbnrDtnNpc2No': 'Français', // Französisch
  'SXRhbGllbmlzY2g=': 'Italien', // Italienisch


  'R3J1bmR2ZXJzaWNoZXJ1bmc=': 'Assurance de base', // Grundversicherung
  'WnVzYXR6dmVyc2ljaGVydW5nIA==': 'Assurance complémentaire', // Zusatzversicherung
  'QlZHIFBMVVM=': 'LPP PLUS', // BVG PLUS
  'QlZHIDEtMw==': 'LPP 1-3', // BVG 1-3
  'VS1CVkcgMQ==': 'LPP-E 1', // U-BVG 1
  'VS1CVkcgMg==': 'LPP-E 2', // U-BVG 2
  'VS1CVkcgMw==': 'LPP-E 3', // U-BVG 3
  'Wi1QbGFu': 'PLAN Z', // Z-Plan
  'QmF1LUthZGVy': 'CADRES DE LA CONSTRUCTION', // Bau-Kader
  'Q1NDLVBsw6RuZQ==': 'Régimes du CSC', // CSC-Pläne

  'R3J1bmR2ZXJzaWNoZXJ1bmcgUFJJTU8=': 'Assurance de base PRIMO', // Grundversicherung PRIMO
  'QlZHIFBMVVMgQiBQUklNTw==': 'LPP PLUS B PRIMO', // BVG PLUS B PRIMO
  'QlZHIFBMVVMgQSBQUklNTw==': 'LPP PLUS A PRIMO', // BVG PLUS A PRIMO
  'QlZHIFBMVVMgQg==': 'LPP PLUS B', // BVG PLUS B
  'QlZHIFBMVVMgQQ==': 'LPP PLUS A', // BVG PLUS A
  'QlZHIDM=': 'LPP 3', // BVG 3
  'QlZHIDI=': 'LPP 2', // BVG 2
  'QlZHIDE=': 'LPP 1', // BVG 1
  'Wi1QbGFuIDMr': 'PLAN Z 3+', // Z-Plan 3+
  'Wi1QbGFuIDM=': 'PLAN Z 3', // Z-Plan 3
  'Wi1QbGFuIDI=': 'PLAN Z 2', // Z-Plan 2
  'Wi1QbGFuIDE=': 'PLAN Z 1', // Z-Plan 1
  'QmF1LUthZGVyIDI=': 'CADRES DE LA CONSTRUCTION 2', // Bau-Kader 2
  'QmF1LUthZGVyIDE=': 'CADRES DE LA CONSTRUCTION 1', // Bau-Kader 1
  'Q1NDIEthZGVy': 'CSC cadres', // CSC Kader
  'Q1NDIEFuZ2VzdGVsbHRl': 'CSC salariés', // CSC Angestellte
};
