export default {
  'SGVycg==': 'Signore', //Herr
  'RnJhdQ==': 'Signora', //Frau
  'RmlybWE=': 'azienda', //Firma

  'bGVkaWc=': 'celibe/nubile', //ledig
  'dmVyaGVpcmF0ZXQ=': 'sposato/a', //verheiratet
  'Z2VzY2hpZWRlbg==': 'divorziato/a', //geschieden
  'dmVyd2l0d2V0': 'vedovo/a', //verwitwet
  'Z2VzZXR6bGljaCBnZXRyZW5udA==': 'separato/a legalmente', //gesetzlich getrennt
  'ZWluZ2V0ci4gUGFydG5lcnNjaGFmdA==': 'unione domestica registrata', //eingetr. Partnerschaft
  'YXVmZ2VsLiBQYXJ0bmVyc2NoYWZ0': 'unione domestica sciolta', //aufgel. Partnerschaft

  'U3RhbW1wZXJzb25hbA==': 'Stammpersonal', //Stammpersonal

  'QXVzdHJpdHQ=': 'Uscita', //Austritt
  'VG9k': 'Decesso', //Tod
  'UGVuc2lvbmllcnVuZw==': 'Pensionamento', //Pensionierung
  'RkFSIG1pdCBXZWl0ZXJ2ZXJzaWNoZXJ1bmc=': 'PEAN con mantenimento della previdenza', //FAR mit Weiterversicherung
  'RkFSIG9obmUgV2VpdGVydmVyc2ljaGVydW5n': 'PEAN senza mantenimento della previdenza', //FAR ohne Weiterversicherung
  'U3RpbGxsZWd1bmcgVmVyc2ljaGVydW5n': 'Chiusura della previdenza', //Stilllegung Versicherung
  'S8O8bmRpZ3VuZyBBRyAoQWx0ZXIgNTgp': 'Disdetta datore di lavoro (età 58)', //Kündigung AG (Alter 58)
  'QXJiZWl0c3VuZsOkaGlnIGJlaSBBdXN0cml0dA==': 'Incapacità al lavoro al momento dell’uscita', //Arbeitsunfähig bei Austritt

  'RGV1dHNjaA==': 'Tedesco', //Deutsch
  'RnJhbnrDtnNpc2No': 'Francese', //Französisch
  'SXRhbGllbmlzY2g=': 'Italiano', //Italienisch

  'R3J1bmR2ZXJzaWNoZXJ1bmc=': 'Assicurazione di base', // Grundversicherung
  'WnVzYXR6dmVyc2ljaGVydW5nIA==': 'Assicurazione supplementare', // Zusatzversicherung
  'QlZHIFBMVVM=': 'LPP PLUS', // BVG PLUS
  'QlZHIDEtMw==': 'LPP 1-3', // BVG 1-3
  'VS1CVkcgMQ==': 'LPP-E 1', // U-BVG 1
  'VS1CVkcgMg==': 'LPP-E 2', // U-BVG 2
  'VS1CVkcgMw==': 'LPP-E 3', // U-BVG 3
  'Wi1QbGFu': 'PIANO C', // Z-Plan
  'QmF1LUthZGVy': 'QUADRI DELLA COSTRUZIONE', // Bau-Kader
  'Q1NDLVBsw6RuZQ==': 'piani CSC', // CSC-Pläne

  'R3J1bmR2ZXJzaWNoZXJ1bmcgUFJJTU8=': 'Assurance de base PRIMO', // Grundversicherung PRIMO
  'QlZHIFBMVVMgQiBQUklNTw==': 'LPP PLUS B PRIMO', // BVG PLUS B PRIMO
  'QlZHIFBMVVMgQSBQUklNTw==': 'LPP PLUS A PRIMO', // BVG PLUS A PRIMO
  'QlZHIFBMVVMgQg==': 'LPP PLUS B', // BVG PLUS B
  'QlZHIFBMVVMgQQ==': 'LPP PLUS A', // BVG PLUS A
  'QlZHIDM=': 'LPP 3', // BVG 3
  'QlZHIDI=': 'LPP 2', // BVG 2
  'QlZHIDE=': 'LPP 1', // BVG 1
  'Wi1QbGFuIDMr': 'PIANO C 3+', // Z-Plan 3+
  'Wi1QbGFuIDM=': 'PIANO C 3', // Z-Plan 3
  'Wi1QbGFuIDI=': 'PIANO C 2', // Z-Plan 2
  'Wi1QbGFuIDE=': 'PIANO C 1', // Z-Plan 1
  'QmF1LUthZGVyIDI=': 'QUADRI DELLA COSTRUZIONE 2', // Bau-Kader 2
  'QmF1LUthZGVyIDE=': 'QUADRI DELLA COSTRUZIONE 1', // Bau-Kader 1
  'Q1NDIEthZGVy': 'CSC cadri', // CSC Kader
  'Q1NDIEFuZ2VzdGVsbHRl': 'CSC dipendenti', // CSC Angestellte
};
