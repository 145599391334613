import request, { extend } from 'umi-request';
import { useIntl, history, IntlProvider } from 'umi';
import { Alert, message, Tabs } from 'antd';
import jwt_decode from "jwt-decode";
import { checkMaintance } from '@/services/ant-design-pro/api';
import Helper from '@/helper/Helper'
import moment from 'moment-timezone';



moment.tz.setDefault('Europe/Zurich');





// Same as the last one
request.interceptors.request.use(
  (url, options) => {

    let currentLang = localStorage.getItem('umi_locale');
    currentLang = currentLang.split('-');
    currentLang = currentLang[0];

    let currentUrl = url.split('/');

    if(currentLang != 'de') {
      currentUrl[0] = currentUrl[1];
      currentUrl[1] = currentLang;
    } else {
      currentUrl.shift();
    }

    let newUrl = currentUrl.join('/');
    newUrl = '/' + newUrl;

    //console.log(newUrl);

    var userInfo = localStorage.getItem('userInfo');
    userInfo = JSON.parse(userInfo);

    let isUserLogin = url.includes("/user/login");
    let isPasswordLost = url.includes("/user/lost-password");
    let isRegister = url.includes("/api/rest/pks/register/create");

    let requestHeader = {
      ['Accept-Language']: 'de-CH'
    };
    if(!isUserLogin && !isPasswordLost && !isRegister && userInfo) {
      requestHeader['Authorization'] = 'Bearer ' + userInfo.access_token;
    }

    //console.log(newUrl);

    return {
      url: newUrl,
      options: { ...options, interceptors: true, headers: requestHeader }
    };
  },
  { global: true }
);




request.interceptors.response.use(async (response) => {

  // checkMaintance
  let maintenanceMode = await checkMaintance();

  if(response.status === 503 || response.status === 403 || maintenanceMode.status === 503) {
    /* Logout User */
    Helper.clearLocalStorage();
    history.push('/user/login');
    return null;
  }

  const data = await response.clone().json();


  if(!data.success && data.message) {
    message.error(Helper.translateString(data.message));
  }

  if(response.status === 302) {
    return null;
  } else if(response.status === 400) {
    return data;
  }
  return response;
})
