export default {
  'dashboard.welcome': 'Une cordiale bienvenue {firstname} {lastname}', // Herzlich willkommen {firstname} {lastname}
  'dashboard.welcome01': 'sur le portail employeurs de la Caisse de pension de la SSE.', // auf dem Arbeitgeberportal der Pensionskasse SBV.
  'dashboard.card.title01': 'PK Schweizerischer Baumeisterveraband', // PK Schweizerischer Baumeisterveraban
  'dashboard.card.title02': 'Raccourcis', // Shortcuts
  'dashboard.card.title03': 'Activités', // Aktivitäten
  'dashboard.address.title01': 'Commerciale', // Geschäftsadresse
  'dashboard.address.title02': 'Postale', // Postfachadresse
  'dashboard.address.title03': 'Contact', // Kontakt
  'dashboard.address.title04': 'lu-ve', // Bürozeiten (Mo-Fr)
  'dashboard.clock': 'heures', // Uhr
  'dashboard.button.contact': 'demande', // Émettre une demande de renseignements
};
