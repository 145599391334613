//console.log('defaultSettings');

let title = 'PK-SBV';


const Settings = {
  navTheme: 'light',
  primaryColor: '#cb4d53',
  layout: 'mix',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: title,
  pwa: true,
  logo: '/pensionskasse-sbv-logo-weiss.svg',
  iconfontUrl: '',
};


export default Settings;
